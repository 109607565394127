import { browser } from '$app/env';
import type { BigAdFragment, MegaAdFragment, SmallAdFragment } from '$gen/graphql';
import { derived, writable } from 'svelte/store';

const mobileBreakPoint = 1024;
export const windowWidth = writable(browser ? window.innerWidth : 1920);
export const isMobileSize = derived(windowWidth, ($windowWidth, set) => {
    set($windowWidth <= mobileBreakPoint);
    // console.log('isMobile', $windowWidth <= mobileBreakPoint);
});

const init: { mega: MegaAdFragment[]; big: BigAdFragment[]; small: SmallAdFragment[] } = {
    mega: [],
    big: [],
    small: [],
};

export const ads = writable(init);
